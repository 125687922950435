.App {
  text-align: center;
  background-image: url('../src/img/bg-image.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size:auto;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.dd{
  width: 50vw;
  margin:10px;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.username{
  margin-top: 10px;
}
.MuiBox-root .css-1t6c9ts{
  justify-content: right;
}

.password{
  margin-top: 10px;
}
.loginButton{
  margin-top: 10px;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;

}

.loginButton .login{
  width: 100%;
}

.forgotPassword{
  margin-top: 10px;
  display: flex;
  justify-content: center;
  gap: 20%;
}
.App-link {
  color: #61dafb;
  
}
.loginCard{
   width: 50vw;
   position: fixed;
   top: 10%;
   left: 25%;
   
}
.signupCard{
  width: 75vw;
  position: fixed;
  top: 2%;
  left: 12.5%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Signup-btn{
  display: flex;
  justify-content: center;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
